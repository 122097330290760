
import action from '../components/action.vue';

export default {
    components: { action },


    created() {
        this.loadSelectorData();
    },

    activated() {
        let t = this;

        if (!B.batchShelving) {
            t.$router.replace('/menu');
            return;
        }

        t.area = B.batchShelving.area;

        t.date = B.batchShelving.date;

        t.list.selectedItem = [];

        t.loadData();
    },

    methods: {

        loadSelectorData() {

            let t = this;

            axios.get('category').then(res => {
                if (res.data.status == 1) {
                    t.list.category = res.data.options.items;
                    t.dict.category = listToDict(t.list.category);
                    t.list.category.unshift({ id: -1, name: '所有分类' });
                }
            });

        },

        loadData() {

            let t = this;

            axios.get('goods').then(res => {
                if (res.data.status == 1) {
                    res.data.options.items.forEach(item => {
                        item.selected = false;
                        item.stock = 10;
                        item.area = t.area;
                    });
                    t.list.item = res.data.options.items;
                }
            });

        },

        showOptions(type) {

            let t = this;

            let arr = [];

            let oneLevelId, twoLevelId, threeLevelId;

            let field = 'name';
            let relation = undefined;
            let column = 1;

            if (type == 'category') {
                arr.push(t.list.category);
                oneLevelId = t.search.category ? t.search.category.id : arr[0][0].id;
            }

            let dict = {
                'category': '菜品分类',
            };

            new IosSelect(column,
                arr,
                {
                    title: dict[type],
                    itemHeight: 60,
                    relation: relation,
                    oneLevelId: oneLevelId,
                    twoLevelId: twoLevelId,
                    threeLevelId: threeLevelId,
                    showLoading: true,
                    closeText: '关闭',
                    sureText: '确认',
                    itemShowCount: 5,
                    field: field || 'value',
                    callback: function (obj1, obj2, obj3) {

                        if (column == 1) {
                            if (obj1.id > -1)
                                t.search[type] = obj1;
                            else
                                delete t.search[type];

                            t.$forceUpdate();
                        }
                    }
                });
        },

        onSearch() {
            let t = this;

            if (t.searchTimeoutID) {
                clearTimeout(t.searchTimeoutID);
                t.searchTimeoutID = null;
            }

            t.searchTimeoutID = setTimeout(() => {
                t.loadData();
            }, 300);
        },

        selectItem(item) {

            let t = this;

            if (!item.selected) {
                item.selected = true;
                t.list.selectedItem.push(item);
            }
            else {
                item.selected = false;
                t.list.selectedItem.remove(item);
            }
        },

        addToMenu() {

            let t = this;

            let items = [];

            if (t.list.selectedItem.length < 1) {
                A.toast('请至少勾选一个菜品', 1000, 'warning');
                return;
            }

            t.list.selectedItem.forEach(item => {
             
                items.push({
                    date: t.date,
                    goods_id: item.id,
                    stock: item.stock,
                    area: t.area
                });

            });

            if (t.busying)
                return;

            t.busying = true;

            axios.post('menu/batchCreate', { 'json_items': JSON.stringify(items), 'date': t.date }).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    A.show('已将' + items.length + '个菜品添加到' + t.date + '菜单', '操作成功', 'success').then(() => {
                        t.$router.replace('/menu?date=' + t.date);
                    });

                }

            });


        },

        itemFilter(items) {

            let t = this;

            let s = t.search;

            let arr = [];

            items.forEach(item => {

                if (
                    (!s.text || (s.text && item.name.indexOf(s.text) > -1)) &&
                    (!s.category || (s.category && item.cate_id == s.category.id))
                )
                    arr.push(item);
            });

            return arr;
        }
    },

    data() {
        return {
            list: {
                category: [],
                item: [],
                selectedItem: []
            },

            dict: {
                category: {}
            },

            search: {
                text: '',
                category: null,
            },

            searchTimeoutID: null,

            date: "",

            area: 1
        };
    },
};
