


export default {

    created() {
        let t = this;

        t.dict.role = listToDict(t.list.role);

        t.loadData();

    },
    methods: {

        loadData() {

            let t = this;

            axios.get('manager').then(res => {
                if (res.data.status == 1) {
                    res.data.options.items.forEach(item => {
                        item.showActions = false;
                    });
                    t.list.manager = res.data.options.items;
                }
            });


        },

        addAccount() {
            let t = this;

            t.accountForm = {
                role_id: 102
            };

            t.openModal('editor');
        },

        editAccount(account) {
            let t = this;

            t.accountForm = getFields(account, ['id', 'name', 'role_id', 'username', 'password']);

            t.openModal('editor');
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        saveAccountForm() {

            let t = this;

            if (t.busying)
                return;

            let f = t.accountForm;

            t.errMsg = "";


            if (!f.name) {
                t.errMsg = "请输入雇员名称";
                return;
            }

            if (!f.username) {
                t.errMsg = "请输入雇员用户名";
                return;
            }

            if (!f.password) {
                t.errMsg = "请输入雇员密码";
                return;
            }

            t.busying = true;

            axios.post('manager', f).then(res => {
                if (res.data.status == 1) {
                    t.closeModal();
                    t.loadData();
                    A.toast('保存成功');
                }
            });

        },

        showOptions(type) {

            let t = this;

            let arr = [];

            let oneLevelId;

            let field = 'name';
            let relation = undefined;
            let column = 1;

            if (type == 'role') {
                arr.push(t.list.role);
                oneLevelId = t.accountForm.role_id;
            }

            let dict = {
                'role': '角色',
            };

            new IosSelect(column,
                arr,
                {
                    title: dict[type],
                    itemHeight: 60,
                    relation: relation,
                    oneLevelId: oneLevelId,
                    showLoading: true,
                    closeText: '关闭',
                    sureText: '确认',
                    itemShowCount: 5,
                    field: field || 'value',
                    callback: function (obj1, obj2, obj3) {

                        if (obj1.id) {
                            if (type == 'role') {
                                t.accountForm.role_id = obj1.id;
                            }
                        }

                    }
                });
        },

        delAccount(item) {
            let t = this;

            A.safety('确认要移除该雇员账户吗？').then(function (res) {
                if (res.isConfirmed) {
                    axios.delete('manager/' + item.id).then(res => {
                        if (res.data.status == 1) {
                            t.list.manager.remove(item);
                        }
                    });
                }
            });
        }

    },
    data() {
        return {
            errMsg: "",
            list: {
                manager: [],
                role: [
                    {
                        'id': 101,
                        'name': '店铺超管'
                    },
                    {
                        'id': 102,
                        'name': '店铺运营'
                    }
                ],
            },

            dict: {
                role: {}
            },

            accountForm: {
                'role_id': '',
                'name': '',
                'username': '',
                'password': ''
            },

            busying: false,

            modal: "",

        };
    },
};
