

export default {

    activated() {
        let t = this;

        let p = getURLParams();

        t.search.date = p.date ? decodeURIComponent(p.date) : (new Date()).format('yyyy/MM/dd');

        if(t.app2){
            t.search.area = 2;
        }

        t.loadData();
    },
    methods: {

        loadData() {

            let t = this;

            let s = t.search;

            let params = {};

            if (s.text)
                params.keywords = s.text;

            if (s.date)
                params.date = s.date;

            if (s.area)
                params.area = s.area;

            axios.get('menu', { params: params }).then(res => {
                if (res.data.status == 1) {
                    res.data.options.items.forEach(item => {
                        item.showActions = false;
                    });
                    t.list.item = res.data.options.items;
                }
            });

        },


        showDatePicker(type) {

            let t = this;

            let dict = {
                'date': '菜单日期',
            };

            var today = new Date();

            if (t.search.date)
                today = new Date(t.search.date);

            var oneLevelId = today.getFullYear();
            var twoLevelId = today.getMonth() + 1;
            var threeLevelId = today.getDate();

            function formatYear(nowYear) {
                var arr = [];
                for (var i = nowYear - 1; i <= nowYear; i++) {
                    arr.push({
                        id: i + '',
                        value: i + ''
                    });
                }
                return arr;
            }

            function formatMonth() {
                var arr = [];
                for (var i = 1; i <= 12; i++) {
                    arr.push({
                        id: i + '',
                        value: i + ''
                    });
                }
                return arr;
            }

            function formatDate(count) {
                var arr = [];
                for (var i = 1; i <= count; i++) {
                    arr.push({
                        id: i + '',
                        value: i + ''
                    });
                }
                return arr;
            }

            var yearData = function (callback) {
                callback(formatYear(today.getFullYear()))
            }

            var monthData = function (year, callback) {
                callback(formatMonth());
            };

            var dateData = function (year, month, callback) {
                if (/^(1|3|5|7|8|10|12)$/.test(month)) {
                    callback(formatDate(31));
                }
                else if (/^(4|6|9|11)$/.test(month)) {
                    callback(formatDate(30));
                }
                else if (/^2$/.test(month)) {
                    if (year % 4 === 0 && year % 100 !== 0 || year % 400 === 0) {
                        callback(formatDate(29));
                    }
                    else {
                        callback(formatDate(28));
                    }
                }
                else {
                    throw new Error('month is illegal');
                }
            };

            new IosSelect(3,
                [yearData, monthData, dateData],
                {
                    title: dict[type],
                    itemHeight: 60,
                    oneLevelId: oneLevelId,
                    twoLevelId: twoLevelId,
                    threeLevelId: threeLevelId,
                    showLoading: true,
                    closeText: '关闭',
                    sureText: '确认',
                    itemShowCount: 5,
                    callback: function (obj1, obj2, obj3) {
                        t.search.date = obj1.value + '/' + (obj2.value.length == 1 ? '0' + obj2.value : obj2.value) + '/' + (obj3.value.length == 1 ? '0' + obj3.value : obj3.value);
                        t.$forceUpdate();
                        t.loadData();
                    }
                });
        },

        onSearch() {
            let t = this;

            if (t.searchTimeoutID) {
                clearTimeout(t.searchTimeoutID);
                t.searchTimeoutID = null;
            }

            t.searchTimeoutID = setTimeout(() => {
                t.loadData();
            }, 300);
        },

        goBatchShelving() {

            B.batchShelving = {
                area: this.search.area,
                date: this.search.date,
            };

            this.$router.push('/batchShelving');

        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        editMenu(menu) {

            let t = this;

            t.menuForm = getFields(menu, ['stock']);

            t.curMenu = menu;

            t.openModal('editMenu');
        },

        saveMenuForm() {

            let t = this;

            let f = t.menuForm;

            if (t.busying)
                return;

            t.errMsg = "";

            if (!f.stock) {
                t.errMsg = "请输入当日菜品库存";
                return;
            }

            if (f.stock <= 0) {
                t.errMsg = "当日库存必须大于0";
                return;
            }

            t.busying = true;

            axios.put('menu/' + t.curMenu.id, f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    A.toast('保存成功');

                    mirror(f, t.curMenu);

                    t.closeModal();

                }
            });



        },

        sort(list, index, offset) {

            if (list.length == 1)
                return;

            let t = this;

            if (t.busying)
                return;

            let targetIndex = index + offset;
            let currentIndex = index;

            if (targetIndex >= 0 && targetIndex < list.length) {

                var currentItem = list[currentIndex];
                var targetItem = list[targetIndex];

                var currentIdx = currentItem.index;
                var targetIdx = targetItem.index;

                //交换在数组中的位置
                list[targetIndex] = currentItem;
                list[currentIndex] = targetItem;

                //交换index字段的值
                currentItem.index = targetIdx;
                targetItem.index = currentIdx;

                let params = {
                    'id': currentItem.id,
                    'index': currentItem.index,
                    'id2': targetItem.id,
                    'index2': targetItem.index
                };

                t.busying = true;

                axios.post('menu/saveIndex', params).then(res => {

                    t.busying = false;

                    t.$forceUpdate();

                    if (res.data.status == 1) {

                    }
                });
            }

        },

        delItem(item) {

            let t = this;

            A.safety('确定要从当日菜单下架该菜品吗？').then(function (res) {
                if (res) {
                    axios.delete('menu/' + item.id).then(res => {
                        if (res.data.status == 1) {
                            t.list.item.remove(item);
                        }
                    });
                }
            });

        },

        itemFilter(items) {

            let t = this;

            let s = t.search;

            let arr = [];

            items.forEach(item => {

                if (
                    (!s.text || (s.text && item.goods.name.indexOf(s.text) > -1))
                )
                    arr.push(item);
            });

            return arr;
        }

    },
    data() {
        return {
            list: {
                category: [],
                item: []
            },

            dict: {
                category: {}
            },

            search: {
                text: '',
                date: '',
                area: 1
            },

            searchTimeoutID: null,

            modal: '',

            errMsg: '',

            menuForm: {
                stock: 1,
            },

            curMenu: null,
        };
    },
};
