


export default {

    created() {

        let t = this;

        t.dict.status = listToDict(t.list.status);

        t.dict.return_status = listToDict(t.list.return_status);

        axios.get('timePoint').then(res => {
            if (res.data.status == 1) {
                t.list.time_point = res.data.options.items;
                t.dict.time_point = listToDict(t.list.time_point);
            }
        });

        if (t.app2) {
            t.search.area = 2;
        }

    },

    destroyed() {

    },

    deactivated() {
        if (this.intervalLoadData != -1)
            clearInterval(this.intervalLoadData);
    },

    activated() {

        let t = this;

        if (t.app2) {
            let curTime = new Date();
            if (curTime.getHours() >= 16) {
                t.curTimeRange = 2;
            }
            else if (curTime.getHours() >= 20) {
                t.curTimeRange = 1;
            }
        }

        t.setView('guide');

        t.intervalLoadData = setInterval(() => {
            if (t.view == 'guide')
                t.loadItem();
            else if (t.view == 'realtime')
                t.loadOrder();
        }, 3000);

    },
    methods: {

        setView(view) {

            let t = this;

            if (view == t.view) {

                if (t.app2) {
                    if (t.view == 'guide') {
                        t.curTimeRange = t.curTimeRange == 1 ? 2 : 1;
                        t.loadItem();
                    }
                }
                return;
            }

            t.view = view;

            if (t.view == 'guide')
                t.loadItem();
            else if (t.view == 'realtime')
                t.loadOrder();
            else if (t.view == 'stats')
                t.loadStats();
        },

        loadOrder() {

            let t = this;

            if (t.busying)
                return;

            //仅读取已支付但未送出的订单
            let params = {
                'status': 2,
                '_order': 'id-1'
            };

            if (t.list.order.length > 0) {
                params.min_id = t.list.order[t.list.order.length - 1].id;
            }

            t.busying = true;

            axios.get('order', { params: params }).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    let items = res.data.options.items;

                    items.forEach(order => {
                        if (order.address)
                            order.address = JSON.parse(order.address);
                        else
                            order.address = {};

                        t.list.order.push(order);
                    });

                    if (res.data.options.canceled_ids && res.data.options.canceled_ids != t.canceled_ids) {

                        t.canceled_ids = res.data.options.canceled_ids;

                        let dict = listToDict(t.list.order);

                        let canceledIds = t.canceled_ids.split(',');

                        let indexs = [];
                        canceledIds.forEach(id => {
                            if (dict[id]) {
                                indexs.push('<b class="f20">#' + dict[id].day_index + '</b>');
                                t.list.order.remove(dict[id]);
                            }
                        });

                        if (indexs.length > 0)
                            A.show("以下订单因为客户申请退款已移除<br>" + indexs.join(' '), "有客户退单", 'warning');

                    }

                    t.lastUpdateTime = (new Date()).format('hh:mm:ss');
                }
            });
        },

        loadItem() {

            let t = this;

            let p = {};

            if (t.app2) {
                p.time_range = t.curTimeRange;
            }

            axios.get('orderGoods/getGuide', { params: p }).then(res => {
                if (res.data.status == 1) {
                    let items = res.data.options.items;
                    t.list.item = items;
                    t.lastUpdateTime = (new Date()).format('hh:mm:ss');
                }
            });

        },

        loadStats() {

            let t = this;

            axios.get('manager/getStatsForHome?area=' + t.search.area).then(res => {
                if (res.data.status == 1) {
                    t.lastUpdateTime = (new Date()).format('hh:mm:ss');
                    t.stats = res.data.options.stats;

                    let chartData = res.data.options.chartData;
                    let labels = [];
                    let values = [];
                    for (let date in chartData) {
                        labels.push(date);
                        values.push(Number(chartData[date]));
                    }

                    setTimeout(() => {

                        var chartDom = document.getElementById('incomeChart');
                        var myChart = echarts.init(chartDom);
                        var option;

                        option = {
                            xAxis: {
                                type: 'category',
                                data: labels,
                            },
                            yAxis: {
                                type: 'value'
                            },
                            grid: {
                                show: true,
                                top: 20,
                                left: 40,
                                bottom: 40,
                                right: 20,
                            },
                            tooltip: {
                                valueFormatter: value => '￥' + value,
                                show: true,
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'cross',
                                    label: {
                                        backgroundColor: '#f06966'
                                    }
                                }
                            },
                            series: [
                                {
                                    data: values,
                                    type: 'bar',
                                }
                            ]
                        };

                        option && myChart.setOption(option);

                    }, 200);
                }
            });

        },

        printOrder(item) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            axios.post('order/print/' + item.id).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    A.toast('打印成功');
                }
            });

        },

        setAsSent(item) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            axios.post('order/setAsSent/' + item.id).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    A.toast('确认出餐成功');
                    t.list.order.remove(item);
                }
            });
        },

        goPage(page) {
            this.$router.push('/' + page);
        },
        goOrders(type) {
            if (type == 'handling')
                this.$router.push('/orders?status=2');
            else if (type == 'completed')
                this.$router.push('/orders?status=3');
            else if (type == 'returning')
                this.$router.push('/orders?return_status=1');
        },
        goStoreInfo(modal) {
            B.storeInfoPage = {
                'modal': modal
            };
            this.$router.push('/storeInfo');
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        showOptions(type) {

            let t = this;

            let arr = [];

            let oneLevelId, twoLevelId, threeLevelId;

            let field = 'name';
            let relation = undefined;
            let column = 1;

            if (type == 'time_point') {
                field = 'delivery_time';
                arr.push(t.list.time_point);
                oneLevelId = t.batchSendForm.time_point ? t.batchSendForm.time_point.id : arr[0][0].id;
            }

            let dict = {
                'time_point': '送餐时间',
            };

            new IosSelect(column,
                arr,
                {
                    title: dict[type],
                    itemHeight: 60,
                    relation: relation,
                    oneLevelId: oneLevelId,
                    twoLevelId: twoLevelId,
                    threeLevelId: threeLevelId,
                    showLoading: true,
                    closeText: '关闭',
                    sureText: '确认',
                    itemShowCount: 5,
                    field: field || 'value',
                    callback: function (obj1, obj2, obj3) {

                        if (type == 'time_point') {
                            if (obj1.id > -1)
                                t.batchSendForm.time_point = obj1;
                            else
                                delete t.batchSendForm.time_point;

                            if (t.batchSendForm.time_point) {

                                axios.get('order/statByDeliveryTime?delivery_time=' + t.batchSendForm.time_point.delivery_time).then(res => {
                                    if (res.data.status == 1) {
                                        t.batchSendForm.count_order = res.data.options.count_order;
                                    }
                                });

                            }

                            t.$forceUpdate();
                        }
                    }
                });
        },

        batchSend() {

            let t = this;

            let f = {};

            if (t.busying)
                return;

            t.errMsg = "";

            if (!t.batchSendForm.time_point) {
                t.errMsg = "请选择送餐时间";
            }

            f.delivery_time = t.batchSendForm.time_point.delivery_time;

            t.busying = true;

            axios.post('order/batchSend', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    A.toast('确认出餐成功');

                    t.batchSendForm = {
                        count_order: "",
                        time_point: null
                    };
                    t.list.order = [];
                    t.closeModal();
                    t.loadOrder();
                }
            });


        },

        printSummary() {

            let t = this;

            let f = t.printForm;

            if (t.busying)
                return;

            t.busying = true;

            axios.post('order/printSummary', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    A.toast('打印成功');
                    t.loadOrder();
                }
                else {
                    A.err('打印失败，没有可打印的订单');
                }

                t.closeModal();
            });

        },

        printAreaTickets() {

            let t = this;

            let f = t.printForm2;

            if (t.busying)
                return;

            t.busying = true;

            axios.post('order/printAreaTickets',f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    A.toast(t.dictArea[f.area]  + '逐张打印成功');
                    t.list.order.remove(item);
                }
                else if (res.data.status == 2) {
                    A.err('打印失败，没有可打印的订单');
                }
            });
        },


    },
    data() {
        return {
            modal: '',
            batchSendForm: {
                count_order: "",
                time_point: null
            },
            printForm: {
                company_id: 1,
                time_range: 1
            },
            printForm2: {
                area: 3,
                time_range: 1
            },
            errMsg: "",
            list: {
                status: [
                    { id: -1, name: '所有状态' },
                    { id: 1, name: '待支付' },
                    { id: 2, name: '待处理' },
                    { id: 3, name: '已送出' },
                    { id: 99, name: '已取消' },
                ],
                return_status: [
                    { id: -1, name: '所有状态' },
                    {
                        id: 1,
                        name: '要求退款',
                    },
                    {
                        id: 2,
                        name: '退款失败',
                    },
                    {
                        id: 3,
                        name: '已退款',
                    },
                    {
                        id: 99,
                        name: '拒绝退款',
                    }],
                item: [],
                order: [],
                time_point: []
            },

            dict: {
                status: {},
                item: {},
                time_point: {}
            },

            search: {
                text: '',
            },

            intervalLoadData: -1,

            view: '',

            lastUpdateTime: '',

            stats: {
                total_location: 0,
                total_time_point: 0,
                total_goods: 0,
                total_category: 0,

                total_returning_order: 0,
                total_shipped_order: 0,
                total_paid_order: 0,

                today_order: 0,
                today_income: 0,

                total_order: 0,
                total_income: 0,
            },

            canceled_ids: '',

            curTimeRange: 1,

            search: {
                area: 1
            },

            showQuickActions: false
        };
    },
};
