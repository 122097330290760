


export default {

    activated() {

        if (B.storeInfoPage) {
            let modal = B.storeInfoPage.modal;

            if (modal) {
                this.openModal(modal);
            }

            delete B.storeInfoPage;
        }

    },
    created() {

        let t = this;

        t.dict.role = listToDict(t.list.role);

        axios.get('storeConfig/getStats').then(res => {
            if (res.data.status == 1) {
                t.stats = res.data.options.stats;
                t.c = res.data.options.config;
                t.infoForm = getFields(t.c, ['name', 'logo_url', 'status', 'summary', 'closing_title', 'closing_desc']);
                t.list.category = res.data.options.categories;
                t.list.location = res.data.options.locations;
                t.list.time_point = res.data.options.time_points;
            }
        });

        let arr1 = [11, 12, 13, 14, 17, 18, 19, 20, 21, 22];
        let arr2 = ['00', '05', 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

        for (let i = 0; i < arr1.length; i++) {
            t.list.hour.push({
                id: arr1[i],
                name: arr1[i]
            });
        }

        for (let i = 0; i < arr2.length; i++) {
            t.list.minute.push({
                id: arr2[i],
                name: arr2[i]
            });
        }

    },
    methods: {

        goPage(page) {
            this.$router.push('/' + page);
        },

        logout() {

            let t = this;

            A.safety('确认要退出登录吗？').then(res => {
                if (res.isConfirmed) {
                    localStorage.removeItem('_m');
                    t.U = null;
                    t.$router.replace('/login');
                }
            });
        },

        openModal(name) {
            this.$parent.disabledScroll = true;
            this.modal = name;
        },

        closeModal() {
            this.modal = '';
            this.$parent.disabledScroll = false;
        },

        savePasswordForm() {

            let t = this;

            let f = t.passwordForm;

            if (t.busying)
                return;

            t.errMsg = "";

            if (!f.old_password) {
                t.errMsg = "请输入旧密码";
                return;
            }

            if (!f.new_password) {
                t.errMsg = "请输入新密码";
                return;
            }

            if (!f.confirm_password) {
                t.errMsg = "请再次输入新密码";
                return;
            }

            if (f.new_password != f.confirm_password) {
                t.errMsg = "两次输入密码不一致";
                return;
            }

            t.busying = true;

            axios.post('manager/modifyPassword', f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {

                    A.show('您的密码已更新，请牢记。', '操作成功', 'success');

                    t.closeModal();

                }
                else
                    t.errMsg = "旧密码错误";
            });



        },

        saveInfoForm() {

            let t = this;

            if (t.busying)
                return;

            let f = t.infoForm;

            t.errMsg = "";

            if (!f.logo_url && !f.base64_logo) {
                t.errMsg = "请上传店铺Logo";
                return;
            }

            if (!f.name) {
                t.errMsg = "请输入店铺名称";
                return;
            }

            if (!f.summary) {
                t.errMsg = "请输入店铺简介";
                return;
            }

            if (f.status != 1 && !f.closing_title) {
                t.errMsg = "请输入歇业标题";
                return;
            }

            if (f.status != 1 && !f.closing_desc) {
                t.errMsg = "请输入歇业说明";
                return;
            }

            t.busying = true;

            axios.put('storeConfig/' + t.c.id, f).then(res => {

                t.busying = false;

                if (res.data.status == 1) {
                    A.toast('保存成功');
                }
            });

        },

        startUpload() {
            var t = this;
            var e = document.querySelector("#fImg");
            e.onchange = function (img) {
                img = this;
                compressImg(img.files[0], { max_height: 800, max_width: 800 }, function (base64) {
                    t.infoForm.base64_logo = base64;
                });
            };
            e.value = "";
            e.click();
        },

        moveUpItem(arr, index, type) {

            let t = this;

            if (t.busying)
                return;

            t.busying = true;

            let prev = arr[index - 1];
            let cur = arr[index];

            let idx = cur.index;

            cur.index = prev.index;
            prev.index = idx;

            arr[index - 1] = cur;
            arr[index] = prev;

            let f = {
                id: cur.id,
                index: cur.index,
                id2: prev.id,
                index2: prev.index
            };

            axios.post(type + '/saveIndex', f).then(res => {

                t.busying = false;

                t.$forceUpdate();

                if (res.data.status == 1) {

                }
            });

        },

        editTimePoint(item) {
            let t = this;

            t.timePointForm = getFields(item, ['id', 'delivery_time']);
            t.editing.timePoint = item;
        },

        delTimePoint(item) {
            let t = this;

            A.safety('确定要删除该项吗？').then(res => {
                if (res.isConfirmed) {
                    t.list.time_point.remove(item);
                    if (t.categoryForm.id == item.id)
                        t.timePointForm = { 'delivery_time': '' };
                    axios.delete('timePoint/' + item.id).then(res => {
                        if (res.data.status == 1) {

                        }
                    });

                }
            });
        },

        saveTimePointForm() {

            let t = this;

            if (t.busying)
                return;

            let f = t.timePointForm;

            t.errMsg = "";

            if (!f.delivery_time) {
                t.errMsg = "请指定送餐时间";
                return;
            }

            t.busying = true;

            if (f.id) {
                axios.put('timePoint/' + f.id, f).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        A.toast('保存成功');
                        t.editing.timePoint.delivery_time = f.delivery_time;
                        t.editing.timePoint = null;
                        f.id = null;
                        f.delivery_time = '';
                    }
                });
            }
            else {
                axios.post('timePoint', f).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        A.toast('保存成功');
                        t.list.time_point.unshift(res.data.options);
                        f.delivery_time = '';
                    }
                });
            }

        },

        editLocation(item) {
            let t = this;

            t.locationForm = getFields(item, ['id', 'name']);
            t.editing.location = item;
        },

        delLocatin(item) {
            let t = this;

            A.safety('确定要删除该项吗？').then(res => {
                if (res.isConfirmed) {
                    t.list.location.remove(item);
                    if (t.locationForm.id == item.id)
                        t.locationForm = { 'name': '' };
                    axios.delete('location/' + item.id).then(res => {
                        if (res.data.status == 1) {

                        }
                    });

                }
            });
        },

        saveLocationForm() {

            let t = this;

            if (t.busying)
                return;

            let f = t.locationForm;

            t.errMsg = "";

            if (!f.name) {
                t.errMsg = "请指定送餐点名称";
                return;
            }

            t.busying = true;

            if (f.id) {
                axios.put('location/' + f.id, f).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        A.toast('保存成功');
                        t.editing.location.name = f.name;
                        t.editing.location = null;
                        f.id = null;
                        f.name = '';
                    }
                });
            }
            else {
                axios.post('location', f).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        A.toast('保存成功');
                        t.list.location.unshift(res.data.options);
                        f.name = '';
                    }
                });
            }

        },

        editCategory(item) {
            let t = this;

            t.categoryForm = getFields(item, ['id', 'name']);
            t.editing.category = item;
        },

        delCategory(item) {
            let t = this;

            A.safety('确定要删除该项吗？').then(res => {
                if (res.isConfirmed) {
                    t.list.category.remove(item);
                    if (t.categoryForm.id == item.id)
                        t.categoryForm = { 'name': '' };
                    axios.delete('category/' + item.id).then(res => {
                        if (res.data.status == 1) {

                        }
                    });

                }
            });
        },

        saveCategoryForm() {

            let t = this;

            if (t.busying)
                return;

            let f = t.categoryForm;

            t.errMsg = "";

            if (!f.name) {
                t.errMsg = "请指定分类名称";
                return;
            }

            t.busying = true;

            if (f.id) {
                axios.put('category/' + f.id, f).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        A.toast('保存成功');
                        t.editing.category.name = f.name;
                        t.editing.category = null;
                        f.id = null;
                        f.name = '';
                    }
                });
            }
            else {
                axios.post('category', f).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        A.toast('保存成功');
                        t.list.category.unshift(res.data.options);
                        f.name = '';
                    }
                });
            }

        },

        showOptions(type) {

            let t = this;

            let arr = [];

            let oneLevelId, twoLevelId, threeLevelId;

            let field = 'name';
            let relation = undefined;
            let column = 1;

            if (type == 'delivery_time') {
                arr.push(t.list.hour, t.list.minute);
                if (t.timePointForm.delivery_time) {
                    let arr2 = t.timePointForm.delivery_time.split(':');
                    oneLevelId = Number(arr2[0]);
                    twoLevelId = Number(arr2[0]);
                }
                column = 2;
            }

            let dict = {
                'delivery_time': '送餐时间',
            };

            new IosSelect(column,
                arr,
                {
                    title: dict[type],
                    itemHeight: 60,
                    relation: relation,
                    oneLevelId: oneLevelId,
                    twoLevelId: twoLevelId,
                    threeLevelId: threeLevelId,
                    showLoading: true,
                    closeText: '关闭',
                    sureText: '确认',
                    itemShowCount: 5,
                    field: field || 'value',
                    callback: function (obj1, obj2, obj3) {

                        if (column == 1) {
                        }

                        if (type == 'delivery_time') {
                            t.timePointForm.delivery_time = obj1.name + ':' + obj2.name;
                        }

                    }
                });
        }

    },
    data() {
        return {
            modal: '',
            dict: {},
            list: {
                role: [
                    {
                        'id': 101,
                        'name': '店铺超管'
                    },
                    {
                        'id': 102,
                        'name': '店铺运营'
                    }
                ],
                time_point: [],
                location: [],
                category: [],
                hour: [],
                minute: []
            },
            stats: {
                total_order: 0,
                total_goods: 0,
                total_location: 0,
                total_time: 0,
                total_category: 0,
                total_manager: 0
            },

            errMsg: "",
            passwordForm: {
                old_password: "",
                new_password: "",
                confirm_password: "",
            },
            infoForm: {
                base64_logo: '',
                status: 1,
                logo_url: "",
                name: "",
                summary: "",
                desc: "",
                closing_title: "",
                closing_desc: "",
            },
            timePointForm: {
                delivery_time: ''
            },
            locationForm: {
                name: ''
            },
            categoryForm: {
                name: ''
            },
            c: null,
            tp: null,
            editing: {
                timePoint: null,
                category: null,
                location: null,
            }
        };
    },
};
