


export default {
    components: {},
    created() {

    },
    activated() {

        let t = this;

        let p = getURLParams()

        if (p.status == 2)
            t.search.status = t.list.status[2];

        if (p.status == 3)
            t.search.status = t.list.status[3];

        if (p.return_status == 1)
            t.search.return_status = t.list.return_status[1];

        t.dict.status = listToDict(t.list.status);

        t.dict.return_status = listToDict(t.list.return_status);

        t.pager.enabled = true;

        t.pager.load = t.loadData;

        t.pager.load();

    },

    deactivated() {
        let t = this;

        t.pager.enabled = false;
    },
    methods: {

        loadData() {

            let t = this;

            let s = t.search;

            let params = {};

            if (s.text)
                params.keywords = s.text;
            if (s.status)
                params.status = s.status.id;

            if (s.return_status)
                params.return_status = s.return_status.id;

            let dateRange = [t.datePicker.start, t.datePicker.end].join(',');

            if (dateRange != ',')
                params.created_at = dateRange;

            params.pageSize = t.pager.size;
            params.pageIndex = t.pager.index;

            t.pager.loading = true;

            axios.get('order', { params: params }).then(res => {

                t.pager.loading = false;

                if (res.data.status == 1) {
                    t.pager.pageTotal = res.data.options.pageTotal;
                    t.pager.itemTotal = res.data.options.itemTotal;
                    res.data.options.items.forEach(order => {
                        if (order.address)
                            order.address = JSON.parse(order.address);
                        else
                            order.address = {};
                    });
                    if (t.pager.index == 1) {
                        window.scrollTo(0, 0);
                        t.list.item = res.data.options.items;
                    }
                    else
                        t.list.item.append(res.data.options.items);
                }
            });

        },

        goOrder(item) {
            this.$router.push('/order?id=' + item.id);
        },

        showDatePicker(type) {

            let t = this;

            let dict = {
                'start': '起始日期',
                'end': '结束日期',
            };

            var today = new Date();

            var oneLevelId = today.getFullYear();
            var twoLevelId = today.getMonth() + 1;
            var threeLevelId = today.getDate();

            function formatYear(nowYear) {
                var arr = [];
                for (var i = nowYear - 1; i <= nowYear; i++) {
                    arr.push({
                        id: i + '',
                        value: i + ''
                    });
                }
                return arr;
            }

            function formatMonth() {
                var arr = [];
                for (var i = 1; i <= 12; i++) {
                    arr.push({
                        id: i + '',
                        value: i + ''
                    });
                }
                return arr;
            }

            function formatDate(count) {
                var arr = [];
                for (var i = 1; i <= count; i++) {
                    arr.push({
                        id: i + '',
                        value: i + ''
                    });
                }
                return arr;
            }

            var yearData = function (callback) {
                callback(formatYear(today.getFullYear()))
            }

            var monthData = function (year, callback) {
                callback(formatMonth());
            };

            var dateData = function (year, month, callback) {
                if (/^(1|3|5|7|8|10|12)$/.test(month)) {
                    callback(formatDate(31));
                }
                else if (/^(4|6|9|11)$/.test(month)) {
                    callback(formatDate(30));
                }
                else if (/^2$/.test(month)) {
                    if (year % 4 === 0 && year % 100 !== 0 || year % 400 === 0) {
                        callback(formatDate(29));
                    }
                    else {
                        callback(formatDate(28));
                    }
                }
                else {
                    throw new Error('month is illegal');
                }
            };

            new IosSelect(3,
                [yearData, monthData, dateData],
                {
                    title: dict[type],
                    itemHeight: 60,
                    oneLevelId: oneLevelId,
                    twoLevelId: twoLevelId,
                    threeLevelId: threeLevelId,
                    showLoading: true,
                    closeText: '关闭',
                    sureText: '确认',
                    itemShowCount: 5,
                    callback: function (obj1, obj2, obj3) {
                        t.datePicker[type] = obj1.value + '/' + (obj2.value.length == 1 ? '0' + obj2.value : obj2.value) + '/' + (obj3.value.length == 1 ? '0' + obj3.value : obj3.value);
                        t.pager.index = 1;
                        t.loadData();
                    }
                });
        },

        showOptions(type) {

            let t = this;

            let arr = [];

            let oneLevelId, twoLevelId, threeLevelId;

            let field = 'name';
            let relation = undefined;
            let column = 1;

            if (type == 'status') {
                arr.push(t.list.status);
                oneLevelId = t.search.status ? t.search.status.id : arr[0][0].id;
            }
            else if (type == 'return_status') {
                arr.push(t.list.return_status);
                oneLevelId = t.search.return_status ? t.search.return_status.id : arr[0][0].id;
            }

            let dict = {
                'status': '订单状态',
                'return_status': '退款状态',
            };

            new IosSelect(column,
                arr,
                {
                    title: dict[type],
                    itemHeight: 60,
                    relation: relation,
                    oneLevelId: oneLevelId,
                    twoLevelId: twoLevelId,
                    threeLevelId: threeLevelId,
                    showLoading: true,
                    closeText: '关闭',
                    sureText: '确认',
                    itemShowCount: 5,
                    field: field || 'value',
                    callback: function (obj1, obj2, obj3) {

                        if (column == 1) {
                            if (obj1.id > -1)
                                t.search[type] = obj1;
                            else
                                delete t.search[type];
                        }
                        t.pager.index = 1;
                        t.loadData();
                    }
                });
        },

        onSearch() {
            let t = this;

            if (t.searchTimeoutID) {
                clearTimeout(t.searchTimeoutID);
                t.searchTimeoutID = null;
            }

            t.searchTimeoutID = setTimeout(() => {
                t.pager.index = 1;
                t.loadData();
            }, 300);
        },
    },
    data() {
        return {
            datePicker: {
                start: '',
                end: '',
            },

            list: {
                status: [
                    { id: -1, name: '所有状态' },
                    { id: 1, name: '待支付' },
                    { id: 2, name: '待处理' },
                    { id: 3, name: '已送出' },
                    {
                        id: 98,
                        name: '退款中',
                    },
                    { id: 99, name: '已取消' },
                ],
                return_status: [
                    { id: -1, name: '所有状态' },
                    {
                        id: 1,
                        name: '要求退款',
                    },
                    {
                        id: 2,
                        name: '退款失败',
                    },
                    {
                        id: 3,
                        name: '已退款',
                    },
                    {
                        id: 98,
                        name: '退款中',
                    },
                    {
                        id: 99,
                        name: '拒绝退款',
                    }],
                item: []
            },

            dict: {
                status: {}
            },

            search: {
                text: '',
                status: null,
                payment: null,
                return_status: null,
            },

            searchTimeoutID: null,

            pager: {
                enabled: true,
                loading: false,
                size: 10,
                index: 1,
                pageTotal: 0,
                itemTotal: 0,
            }
        };
    },
};
